@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,500;0,600;0,700;1,400&display=swap");

@layer components {
  * {
    @apply font-[Lato] m-0 p-0 box-border;
  }
  /* body {
    @apply print:hidden;
  } */

  .pageLayout {
    @apply min-h-screen print:pt-0 print:lg:pt-0 print:p-0 print:md:p-0  print:md:pt-0 overflow-hidden;
  }

  .authPageLayout {
    @apply min-h-screen bg-[#F5F7F7];
  }

  .landvoice-logo {
    @apply flex mb-10 text-white tracking-widest gap-[5px];
  }

  .landvoice-logo p {
    @apply text-xl font-extrabold leading-6 text-left;
  }

  .landvoice-logo span {
    @apply text-[20px] font-[300] leading-6 text-left tracking-[0.142em];
  }

  .navbar {
    @apply bg-white h-20 flex justify-start items-center p-[24px];
  }

  .menu-bars {
    @apply ml-8 text-3xl text-black bg-transparent;
  }

  .nav-menu {
    @apply bg-white w-56 h-screen flex justify-between fixed top-0 left-0 bg-gray-900 flex-col p-[24px] transition-all duration-100;
  }

  .nav-text {
    @apply flex justify-start  list-none;
  }

  .fade-out {
    @apply hidden opacity-0;
  }

  .tabButton {
    @apply text-[16px] rounded-[8px] font-[500] leading-[28px] text-[#666666] px-[16px] py-[6px] md:hover:bg-[#F5F7F7] md:hover:cursor-pointer;
  }

  .tabButtonSelected {
    @apply bg-[#F27935]  text-white text-[16px] rounded-[8px] font-[500] leading-[28px] px-[16px] py-[6px];
  }
  .tab {
    @apply p-[6px] w-full bg-white items-center rounded-[8px] gap-[16px] border-[#EBEBEB] border-[1px] mb-[16px];
  }

  .loginInput {
    @apply selection:bg-[#FFEFE6] align-middle focus:outline-[#F27935] hover:outline-[#F27935] active:outline-[#F27935] w-full bg-[#F5F7F7] px-[12px] py-[8px] border-[1px] border-[#E0E0E0] rounded-sm placeholder:text-[#999999] text-[14px] font-[400] leading-[20px] placeholder:text-[14px] placeholder:font-[400] placeholder:leading-[20px] mb-8;
  }

  .selectInput {
    @apply focus:outline-[#F27935] hover:outline-[#F27935]  bg-[#F5F7F7] px-[12px] py-[8px] border-[1px] border-[#E0E0E0]   text-[14px] font-[400] leading-[20px];
  }
  .loginDivider {
    @apply bg-[#EBEBEB] h-[2px] w-full;
  }

  .sidebar-tooltip-Before {
    @apply content-['\A'] border-solid border-t-[10px] border-r-[15px] border-b-[10px] border-l-0 left-[-25px] relative border-t-transparent border-r-[#475569] border-b-transparent border-l-transparent;
  }
  .sidebar-tooltip {
    @apply before:sidebar-tooltip-Before
  }
  .default-tooltip,
  .sidebar-tooltip {
    @apply ml-[10px] z-[1000] text-white text-start font-[lato] font-[14px] h-[44px] flex  items-center py-[16px] px-[1px] pr-[16px] !bg-[#475569] min-w-[20px];
  }

  .editInfoButton {
    @apply flex gap-[6px] text-[#666666] text-center items-center py-1 px-2 rounded-[9999px] max-w-[107] min-w-[82px] h-6 bg-[#fa75324d] mt-2;
  }

  .addInfoButton {
    @apply flex gap-[6px] text-[#999] items-center py-1 px-2 rounded-[9999px] max-w-[107] min-w-[82px] h-6 border-[#666666] border mt-2;
  }
  .selectDropDown {
    @apply absolute mt-1 max-h-56  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
  }

  .crossOut {
    @apply after:absolute after:w-full after:h-[1px] after:bg-black after:left-0 after:top-1/2 after:transform after:rotate-12;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    /* Orange color #fa7532 */
    --primary: 20 95% 59%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
    --tabs-bg: #F5F7F7;
    --tabs-active-bg: #FFFFFF;
    --tabs-active-color: #000000;
  }
 
  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply font-sans antialiased bg-background text-foreground;
  }
}

:root {
  --background: 255, 255, 255; /* Light mode background */
  --foreground: 0, 0, 0;       /* Light mode foreground */
}

.dark {
  --background: 15, 15, 15;    /* Dark mode background */
  --foreground: 240, 240, 240; /* Dark mode foreground */
}